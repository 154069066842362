
export default {
  props: {
    heros: {
      type: Array,
      default: () => [],
    },

    category: {
      type: Object,
      default: null,
    },
  },
}
