import { render, staticRenderFns } from "./PageHasHeroWithoutImage.vue?vue&type=template&id=371bb258&scoped=true"
import script from "./PageHasHeroWithoutImage.vue?vue&type=script&lang=js"
export * from "./PageHasHeroWithoutImage.vue?vue&type=script&lang=js"
import style0 from "./PageHasHeroWithoutImage.vue?vue&type=style&index=0&id=371bb258&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "371bb258",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sidebar: require('/vercel/path0/components/general/navigation/Sidebar.vue').default,HeroLoader: require('/vercel/path0/components/loaders/HeroLoader.vue').default,ComponentLoader: require('/vercel/path0/components/loaders/ComponentLoader.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
