
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    navigateToParentPage() {
      const parent = this.breadcrumbs[this.breadcrumbs.length - 2]

      this.$router.push(parent.url)
    },
  },
}
