
import { mapState } from 'vuex'

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },

    page: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      tl: null,
      breadcrumbs: [],
    }
  },

  computed: {
    ...mapState('navigations', { main: 'unfilteredMain' }),
  },

  async mounted() {
    this.generateBreadcrumbsFromCurrentRoutePath()
    await this.$nextTick()
    this.initAnimation()
    this.setScrollPosition()
  },

  beforeDestroy() {
    this.ctx?.revert()
  },

  methods: {
    initAnimation() {
      this.ctx = this.$gsap.context((context) => {
        const breadcrumbs = context.selector('.slide-in-left')[0]

        if (!breadcrumbs) {
          return
        }
        this.tl = this.$gsap
          .timeline({
            paused: true,
          })
          .to(breadcrumbs, {
            opacity: 1,
            duration: 0.3,
            stagger: 0.12,
          })
      }, this.$el)
    },

    /**
     * Generate breadcrumbs links from main navigation
     */
    generateBreadcrumbsFromCurrentRoutePath() {
      const routePath = this.$route.path
      const routeSegments = routePath.split('/').filter((item) => !!item)

      // push current page as breadcrumb when page has no sidebar
      if (routeSegments.length === 1 && this.page) {
        return this.breadcrumbs.push(this.page)
      }

      // when page has multiple levels
      this.main?.tree.forEach((level1) => {
        if (!routePath.includes(level1.page.url)) {
          return
        }

        if (level1.page.is_right || level1.page.is_cta_button) {
          return
        }

        this.breadcrumbs.push(level1.page)

        if (level1.children) {
          level1.children.forEach((level2) => {
            if (!routePath.includes(level2.page.url)) {
              return
            }

            this.breadcrumbs.push(level2.page)

            if (level2.children) {
              level2.children.forEach((level3) => {
                if (!routePath.includes(level3.page.url)) {
                  return
                }

                this.breadcrumbs.push(level3.page)

                if (level3.children) {
                  level3.children.forEach((level4) => {
                    if (!routePath.includes(level4.page.url)) {
                      return
                    }

                    this.breadcrumbs.push(level4.page)
                  })
                }
              })
            }
          })
        }
      })
    },

    isOverflowing() {
      return this.$el.scrollWidth >= this.$el.clientWidth
    },

    setScrollPosition() {
      if (this.isOverflowing()) {
        this.$gsap.delayedCall(0.5, () => {
          this.$el.scrollLeft = this.$el.scrollWidth

          this.tl?.play()
        })
      }
    },
  },
}
