
export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    /**
     * Disable animation for hero_detailpage and hero_suma
     */
    shouldDisableAnimation() {
      return (
        (this.page.heros.length > 0 &&
          this.page.heros[0].type === 'hero_detailpage') ||
        this.page.heros[0].type === 'hero_suma' ||
        !!this.$route.query['live-preview']
      )
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.initAnimation()
    })
  },

  methods: {
    initAnimation() {
      const q = this.$gsap.utils.selector(this.$el)

      if (q('.hero').length === 0 || q('.content-container').length === 0) {
        return
      }

      const breakpoint = 1024

      // mobile
      this.$gsap.matchMedia().add(`(max-width: ${breakpoint - 1}px)`, () => {
        const tl = this.$gsap
          .timeline({ paused: true })
          .to(q('.content-container'), {
            opacity: 1,
            duration: 0.6,
          })

        this.$nuxt.$on('hero-animated', () => tl.play())
      })

      // desktop
      this.$gsap.matchMedia().add(`(min-width: ${breakpoint}px)`, () => {
        this.$gsap
          .timeline()
          .to(q('.hero'), {
            opacity: 1,
            duration: 0.5,
          })
          .to(q('.content-container'), {
            opacity: 1,
            duration: 0.5,
          })
      })
    },
  },
}
