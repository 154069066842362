
import sidebar from '~/mixins/sidebar'

export default {
  mixins: [sidebar],

  props: {
    page: {
      type: Object,
      default: null,
    },

    heros: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    /**
     * Hide breadcrumbs when hero type is "hero_homepage"
     * aka hide on home page
     */
    isHeroHomepage() {
      return (
        (this.heros.length > 0 && this.heros[0].type !== 'hero_homepage') ||
        this.heros.length === 0
      )
    },
  },
}
