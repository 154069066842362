
export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.initAnimation()
    })
  },

  methods: {
    initAnimation() {
      if (!this.$el) {
        return
      }

      this.$gsap.to(this.$el, {
        delay: 0.2,
        opacity: 1,
        duration: 0.6,
      })
    },
  },
}
