
export default {
  props: {
    components: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getType(component) {
      if (!component.type) {
        console.error('ComponentLoader: Component has no type', component)
        return null
      }

      // for fieldtype: Bard
      if (component.type === 'text') {
        return 'general-text'
      }

      if (component.type === 'video') {
        return 'video-default'
      }

      return component.type.replace(/_/g, '-')
    },
  },
}
